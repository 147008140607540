<template>
  <div id="News">
    <div class="box">
      <div style="width: 1200px;height: 576px; overflow: hidden;">
      <img
        class="box-banner shous"
        mode="bottom"
        :src="
          'https://gxzw.linjiaxiaoda.com/picture/prod-api' + contentTwo[0].cover
        "
        @click="goInfo(contentTwo[0])"
      />
      </div>
      <div class="tac fs40 fw6" style="margin: 61px 0 14px" @click="goInfo(contentTwo[0])">
        <!-- 推动智慧社区更智慧 -->
        {{contentTwo[0].title}}
      </div>
      <div class="box-text fs18 hui666 tac">
        <!-- 到2025年，基本构建起网格化管理、精细化服务、信息化支撑、开放共享的智慧社区服务平台，初步打造成智慧共享、和睦共治的新型数字社区。 -->
        {{contentTwo[0].withTitle}}
      </div>
      <div class="box-type flex ALcenter JCspaceBetween">
        <div
          v-for="(item, index) in typeList"
          :key="index"
          class="box-type-por shous"
          @click="clicSw(item)"
        >
          <span class="fw6 fs28" :class="item.id == type ? 'redf89' : ''">{{
            item.name
          }}</span>
          <div class="box-type-por-poa flex JCcenter" v-if="item.id == type">
            <div class="box-type-por-poa-L"></div>
            <div class="box-type-por-poa-R"></div>
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <div
        class="box-boxli flex shous"
        v-for="(item, index) in contentTwo"
        :key="index"
        @click="goInfo(item)"
      >
        <div class="box-boxli-img" style="overflow: hidden;">
          <img
            style="height:100%;"
            :src="'https://gxzw.linjiaxiaoda.com/picture/prod-api' + item.cover"
          />
        </div>
        <div class="box-boxli-box">
          <div class="box-boxli-box-title fw5 fs24 textOne">
            {{ item.title }}
          </div>
          <div class="box-boxli-box-fu fs18 hui666 text5">
            {{ item.withTitle }}
          </div>
          <div class="box-boxli-time flex JCspaceBetween ALcenter">
            <div class="fs16 hui999">{{ item.createTime }}</div>
            <div
              :class="index == 0 ? 'box-boxli-time-have' : 'box-boxli-time-no'"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <Fuwu />
  </div>
</template>

<script>
import Fuwu from "@/components/Fuwu.vue";
import { getAllList } from "@/api/new";
export default {
  name: "News",
  components: {
    // Tabbar,
    // Bottombox,
    Fuwu,
  },
  data() {
    return {
      contentTwo: [],
      list: [],
      type: 1,
      typeList: [
        {
          id: 1,
          name: "公司动态",
        },
        {
          id: 2,
          name: "行业新闻",
        },
        {
          id: 3,
          name: "媒体报道",
        },
      ],
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    /**
     * 获取列表
     */
    getlist() {
      let obj = {};
      getAllList(obj).then((res) => {
        console.log(res);
        this.list = res.data;
        this.contentTwo = res.data.CompanyDynamics;
      });
    },
    clicSw(e) {
      this.type = e.id;
      this.contentTwo = [];
      if (e.id == 1) {
        this.contentTwo = this.list.CompanyDynamics;
      } else if (e.id == 2) {
        this.contentTwo = this.list.IndustryNews;
      } else if (e.id == 3) {
        this.contentTwo = this.list.MediaNews;
      }
    },
    goInfo(e) {
      console.log(e.id);
      this.$router.push({ path: "/news/details", query: { id: e.id } });
    },
  },
};
</script>

<style scoped lang="less">
#News {
  .box {
    width: 1200px;
    margin: 0 360px;
    .box-banner {
      width: 1200px;
      // height: 576px;
    }
    .box-text {
      width: 1010px;
      margin: 0 95px;
      line-height: 32px;
    }
    .box-type {
      width: 1056px;
      margin: 0 72px;
      height: 173px;
      .box-type-por {
        position: relative;
        .box-type-por-poa {
          width: 84px;
          height: 4px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -100%;
          .box-type-por-poa-L {
            width: 60px;
            height: 4px;
            margin-right: 8px;
            background: #f8951d;
          }
          .box-type-por-poa-R {
            width: 16px;
            height: 4px;
            background: #f8951d;
          }
        }
      }
    }
    .box-boxli {
      width: 1200px;
      height: 405px;
      background: #ffffff;
      box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.1);
      margin-bottom: 50px;
      .box-boxli-img {
        width: 540px;
        height: 405px;
        margin-right: 45px;
      }
      .box-boxli-box {
        width: 543px;
        .box-boxli-box-title {
          margin: 75px 0 40px;
        }
        .box-boxli-box-fu {
          height: 134px;
          line-height: 48px;
        }
        .box-boxli-time {
          width: 543px;
          margin-top: 58px;
          .box-boxli-time-have {
            width: 29px;
            height: 7px;
            background-image: url(~@/assets/newhave.png);
            background-size: 100% 100%;
          }
          .box-boxli-time-no {
            width: 29px;
            height: 7px;
            background-image: url(~@/assets/newno.png);
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
</style>